import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TextSection from "../../components/textSection"
import Principles from "../../components/principles"

const SmartOutstaffing = (props) => {
	const data = useStaticQuery(graphql`
		query smartOutstaffing {
			dnhCms {
				subPages(where: {pageTitle: "Smart outstaffing"}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					copy
					pageBanner {
						url
					}
					infoCards {
						id
						heading
						copy
						icon {
							url
							fileName
						}
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription} />
				<TextSection heading={data.dnhCms.subPages[0].heading}
							copy={data.dnhCms.subPages[0].copy}
							textColor="blue"/>
				<Principles cardData={data.dnhCms.subPages[0].infoCards}/>
			</Layout>
		</>
	)
}

SmartOutstaffing.propTypes = {
	location: PropTypes.object
}

export default SmartOutstaffing
