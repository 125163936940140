import React from "react"

const Principles = ( cardData ) => (
	<div className="principles-wrap container">
		{cardData.cardData.map((principles, index) => (
			<div className="principles__item" key={principles.id}>
				<h4>{principles.heading}</h4>
				<div className="square-image"
					style={{backgroundImage: `url(${principles.icon.url})`}}
					item-numb={`0${index + 1}`}>
				</div>
				<p>{principles.copy}</p>
				<i className="fas fa-chevron-right"></i>
			</div>
		))}
	</div>
)
export default Principles
